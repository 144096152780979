//请求接口封装
import axios from 'axios'
import getToken from './getToken';
export function request(config) {
    const instance = axios.create({
            //baseURL: process.env.VUE_APP_BASE_URL,
            timeout: 5000
        })
        //请求拦截
    instance.interceptors.request.use(config => {
        if (projectConfig.is_jwt=="false") {
            return config
          }else{
          let token = localStorage.getItem("_t");
          var dateString = localStorage.getItem("_tt");
          var timenow = new Date().getTime();
      
          if (!token || timenow > dateString) {
            return getToken().then(token => {
              config.headers["Authorization"] = "Bearer " + token
              return config
            })
          } else {
            config.headers["Authorization"] = "Bearer " + token
            return config
          }
        }
        }, error => { })
        //响应拦截
    instance.interceptors.response.use(res => {
        
        return res.data
    }, err => {
        
    })
    return instance(config)
    // .then(res => {
    //     resolve(res)
    // }).catch(err => {
    //     reject(err)
    // })
}