import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
//富文本
//import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/element.css';
import './assets/css/element-variables.scss'
import * as echarts from 'echarts';
import Fragment from 'vue-fragment'
import store from './store'

import Api from '@/apis/api.js'
import { request } from '@common/request';
import * as _ from 'lodash'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
//事件总线
Vue.prototype.$bus = new Vue()
Vue.prototype.$Api =Api
Vue.prototype.$Request = request
Vue.use(ElementUI);
Vue.use(Fragment.Plugin);
Vue.prototype.$_ = _;
//Vue.use(CKEditor);

if (process.env.NODE_ENV === 'production') {
   console.log = () => {}
}

Vue.directive('focus', {
    inserted(el, binding, vnode) {
        // 聚焦元素
        el.querySelector('input').focus()
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')