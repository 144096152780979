import store from '../../store'
import router from '../../router'
import { request } from '@common/request';
import Api from '@/apis/api.js';
let code = getUrlParam('code');
//获取地址栏参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
/**
 *登出方法
 *
 * @export
 */
export function logout() {
    let redirectUrl = document.URL;
    if (redirectUrl.indexOf("&code") >= 0) {
        redirectUrl = redirectUrl.replace("&code=" + code, "");
    } else {
        redirectUrl = redirectUrl.replace("?code=" + code, "");
    }
    redirectUrl = encodeURIComponent(redirectUrl);
    store.commit("setLoginStatus", false);
    sessionStorage.removeItem('userInfo')
    window.location = Api.logOut + redirectUrl+"&login_page=jqx";
}
/**
 *登录方法
 *
 * @export
 */
export function getLoginPage() {
    let redirectUrl = document.URL;
    if (redirectUrl.indexOf("&code") >= 0) {
        redirectUrl = redirectUrl.replace("&code=" + code, "");
    } else {
        redirectUrl = redirectUrl.replace("?code=" + code, "");
    }
    redirectUrl = encodeURIComponent(redirectUrl);
    if (!code || code == "") {
        window.location =
            Api.logIn +
            "?client_id=appid&redirect_uri=" +
            redirectUrl +
            "&login_page=jqx"+
            "&response_type=code&scope=read";
    } else {
        if (sessionStorage.getItem('userInfo')) {
            //向vuex中提交登录状态 登录状态在路由 前置路由守卫中判断是否登录
            store.commit("setLoginStatus", true);
            router.push({ name: "Home" });
            //用户信息 刷新页面时vuex是不可访问的 把变量放在sessionStorage 如果登录状态没有失效 就直接取
            store.commit("setUserInfo", JSON.parse(sessionStorage.getItem('userInfo')));
        } else {
            //获取用户
           getuser(redirectUrl);
        //   store.commit("setLoginStatus", true);
        //   router.push({ name: "Home" });
        }
    }
}
//业务代码 获取用户信息
function getuser(redirectUrl) {
    /********************业务逻辑 根据业务写 */
    let params = {
        code: code,
        redirect_uri: redirectUrl,
    };
    //获取用户信息
    request({
            method: 'get',
            url: Api.oauthGetCurrentUser,
            params
        }).then((result) => {
            // debugger
            console.log(result);
            if (result && result.errCode == "0") {
                //获取成功 方法
                store.commit("setUserInfo", result.data);
                //用户信息 刷新页面时vuex是不可访问的 把变量放在sessionStorage 如果登录状态没有失效 就直接取
                sessionStorage.setItem('userInfo', JSON.stringify(result.data));
                //向vuex中提交登录状态 登录状态在路由 前置路由守卫中判断是否登录
                store.commit("setLoginStatus", true);
                router.push({ name: "Home" });
            } else {
                window.location = Api.logOut + redirectUrl;
            }
        })
        .catch((err) => {
            console.log(err);
        });
}