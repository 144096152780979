<template>
  <div style="position: relative">
    <!-- 图片上传 -->
    <div v-show="imgsrc" class="avatar-uploader el-upload imgLoadBox">
      <img :src="(imgsrc ? '' : '') + imgsrc" class="avatar" />
      <div class="imgBoxmMask"></div>
      <i class="el-icon-delete" @click="handleRemove(filedname)"></i>
      <i
        class="el-icon-zoom-in"
        @click="previewImg(filedname)"
        :style="{ 'margin-left': '25%' }"
      ></i>
    </div>
    <!-- 该处的elementUI只是为了显示原来的样式，不起任何上传的作用 -->
    <el-upload
      v-show="!imgsrc"
      class="avatar-uploader"
      name="Filedata"
      action=""
      list-type="picture_card"
      :show-file-list="false"
      ref="imgUpload"
    >
      <img v-show="imgsrc" :src="imgsrc" class="avatar el-icon-plus" />
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible[filedname]">
      <img width="100%" :src="(imgsrc ? '' : '') + imgsrc" alt />
    </el-dialog>
    <!-- 此处input才是上传的部分 -->
    <input
      v-show="!imgsrc"
      accept=".png,.jpg,.jpeg,.gif"
      type="file"
      name=""
      class="file"
      @change="change"
      ref="fileUpload"
    />
  </div>
</template>

<script>
const stream = require("stream");
import {
  toBlob,
  getUploadUrl,
  removeObject,
  uploadByUrl,
} from "@minio/minio";
import { getNowTime, getImgName } from "@common/publicmethods";

export default {
  props: ["imgsrc", "filedname",'source'],
  data() {
    return {
      loadprogress: [],
      dialogVisible: {},
      form: {},
      upload: false,
      url: null,
      isDel: false, // 是否删除图片
      saveImg:[]
    };
  },
  methods: {
    handleAvatarSuccess(url, name) {
      this.$bus.$emit("imgUploaded", url, name ,this.source);
    },
    //删除图片
    handleRemove(itemname) {
      this.$confirm("此操作将永久删除服务器的文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.imgsrc)
          removeObject(getImgName(this.imgsrc))
            .then((res) => {
              
              this.$bus.$emit("imgRemoved", itemname,this.source);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //预览图片
    previewImg(itemname) {
      this.$set(this.dialogVisible, itemname, true);
    },

    change(e, fileList) {
      console.log(e,fileList);
      let file = this.$refs.fileUpload.files[0] || e.target.files[0];
      let fileSize = (file.size/ 1024 / 1024).toFixed(2);
      
      /**
       *
       * 普通的上传，返回url
       * 需要两个参数，一个是文件，一个是要存储的路径，minio上没有
       * 的话就会自动创建
       *
       * 这里使用stream上传，并且函数写在了methods里面,跟minio.js
       * 中封装的略有不同
       */
      //this.uploadMinIo(file, "test");

      /**
       *
       * 通过url上传，用fetch，put方法，但是不返回任何结果，
       * 通过设置永久链接，此方法较上面的上传更简洁方便，推荐使用
       * 
       */

      let fileName = getNowTime("time") + file.name; // 获取带时间的文件名
      getUploadUrl(`images`, fileName)
        .then((res) => {
          console.log("res", res);
          uploadByUrl(res, file)
            .then((res) => {
              console.log(res)
              let response = {
                path: this.getImgName(res.url),
              };
              
              if(fileSize > 5){
                this.$message({
                  message: `当前图片大小为${fileSize}MB,请上传小于5MB的图片`,
                  type: "wran",
                });
              }else{
                this.handleAvatarSuccess(response, this.filedname);
                this.$message({
                  message: "图片上传成功！",
                  type: "success",
                });
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // url解析
    getImgName(url) {
      if (url) {
        let index = url.lastIndexOf("?");
        url = index == -1 ? url : url.substring(0, index);
        return url;
      }
    },
  },
  mounted(){
    console.log(this.imgsrc)
  }
};
</script>

<style scoped>
/* 图片盒子 */

.imgLoadBox {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  z-index: 9;
  background: #e4e4e4;
}

/* 图片遮罩 */

.imgBoxmMask {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

/* 删除图标 */

.imgLoadBox > i {
  position: absolute;
  color: #fff;
  font-size: 20px;
  top: 50%;
  left: 37.5%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.imgLoadBox:hover > .imgBoxmMask {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
}

.imgLoadBox:hover > i {
  opacity: 1;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.file {
  width: 178px;
  height: 178px;
  line-height: 178px;
  opacity: 0;
  border-radius: 6px;
  position: absolute;
  top: 1px;
  left: 1px;
  outline: none;
}
.file:hover {
  cursor: pointer;
}
</style>