<template>
  <div></div>
</template>

<script>
import { getLoginPage } from "./conf/commonFunc";
export default {
  data() {
    return {};
  },
  created() {
    getLoginPage();
  }
};
</script>

<style>
</style>