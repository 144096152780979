<template>
  <div>
    <el-dialog
      title="价格日历"
      :visible.sync="dialogVisible"
      width="30%"
      custom-class="customDialog"
    >
      <el-calendar>
        <template #dateCell="{ date, data }">
          <div
            @click="onChange(date, $event)"
            :class="{
              disabled: isDisabled(date, data.day),
              'is-selected': data.isSelected,
            }"
            class="date-txt"
          >
            <p>
              {{
                data.day
                  .split("-")
                  .slice(1)
                  .join("-")
              }}
            </p>
            <p class="price">
              {{ price(data.day) ? "￥" + price(data.day) : "" }}
              <!-- {
              "isSelected": true,
              "type": "current-month",
              "day": "2024-08-01"
              } -->
            </p>
          </div>
        </template>
      </el-calendar>
      <!--  :title="`编辑产品价格${form.currentDate}`" -->
      <el-dialog
        width="30%"
        title="编辑产品价格"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-form
          :validate-on-rule-change="false"
          :model="form"
          :rules="rules"
          ref="form"
          :label-width="formLabelWidth"
        >
          <el-form-item label="日期">
            <el-input
              v-model="form.currentDate"
              autocomplete="off"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="原价" v-show="form.originalPrice">
            <el-input
              disabled
              v-model.number="form.originalPrice"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="售价" prop="price">
            <el-input v-model.number="form.price" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="批量设置" prop="isBatchSetting">
            <el-switch v-model="form.isBatchSetting"> </el-switch>
          </el-form-item>
          <el-form-item
            prop="date"
            label="日期范围"
            v-show="form.isBatchSetting"
          >
            <el-date-picker
              v-model="form.date"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="{ disabledDate: this.isDisabled }"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 外层弹出框
      dialogVisible: false,
      // 内层弹出框
      innerVisible: false,
      // 日期价格列表
      list: [],
      // 表单数据
      form: {
        // 当前点击的日期表格
        currentDate: "",
        // 原价
        originalPrice: "",
        // 新的价格
        price: "",
        // 是否批量设置
        isBatchSetting: false,
        // 时间段
        date: "",
      },
      // 表单label的宽度
      formLabelWidth: "100px",
      // 父组件传递的商品row_id
      row_id: "",
      // 当前日期，商品的详细信息
      currentPriceData: {},
      // 表单校验规则
      rules: {
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [
          {
            // type: "date",
            required: false,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    async row_id() {
      let res = await this.getDataOfResource();
      this.list = res.data;
    },
    // 关闭内层弹出框，重置表单
    innerVisible(newVal) {
      if (!newVal) {
        this.form.date = "";
        this.resetForm("form");
      }
    },
    // 根据是否选择批量修改动态调整rules校验规则
    "form.isBatchSetting"(newVal) {
      if (newVal) {
        this.rules.date = [
          { required: true, message: "请选择时间", trigger: "change" },
        ];
      }
    },
  },
  methods: {
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.rules = {
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        date: [
          {
            // type: "date",
            required: false,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      };
    },
    // 修改价格
    modifyDataOfResource() {
      /*
        存在两种情况
          1.已有数据，直接修改价格
          2.没有数据，新增记录
      */
      let params = { data: {}, directory_code: "ticketprice" };
      // 已有记录，直接修改价格
      if (this.currentPriceData) {
        params.data = {
          row_id: this.currentPriceData.row_id,
          online_price: this.form.price,
        };

        return this.$Request({
          method: "post",
          url: this.$Api.modifyDataOfResource,
          data: params,
        });
      } else {
        // 没有数据，新增记录
        params.data = {
          ticket_id: this.row_id,
          online_price: this.form.price,
          start_date: this.form.currentDate,
        };

        return this.addDataOfResource(params);
      }
    },
    // 新增记录
    addDataOfResource(params) {
      return this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: params,
      });
    },
    // 批量修改价格
    batchModifyDataOfResource() {
      let {
        price,
        date: [date_start, date_end],
      } = this.form;
      let params = {
        ticket_id: this.row_id,
        price,
        date_start,
        date_end,
        directory_code: "ticketprice",
      };

      return this.$Request({
        url: this.$Api.batchModifyTicketPrice,
        method: "get",
        params,
      });
    },
    // 获取价格
    getDataOfResource() {
      let params = {
        data: {
          ticket_id: this.row_id,
        },
        directory_code: "ticketprice",
      };
      return this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      });
    },
    // 父组件调用，开启弹出框，接受传递的商品id
    openCalender(row_id) {
      this.row_id = row_id;
      this.dialogVisible = true;
    },
    // 提交表单
    async submitForm(formName) {
      // 勾选批量设置
      /*
        存在两种情况
          1.未选批量设置，直接修改
          2.选择批量设置
      */
      // 通过校验isValidate为真，否则为undefined
      let isValidate = await this.$refs[formName].validate().catch(() => {});
      if (!isValidate) return;
      let result = {};
      if (this.form.isBatchSetting) {
        result = await this.batchModifyDataOfResource();
      } else {
        result = await this.modifyDataOfResource();
      }
      if (result.errCode == 0 || result.errcode == 0) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        // 关闭内层弹框
        this.innerVisible = false;
        // 更新list
        let res = await this.getDataOfResource();
        this.list = res.data;
      } else {
        this.$message({
          message: result.errmsg || result.errMsg,
          type: "error",
        });
        // 关闭内层弹框
        this.innerVisible = false;
      }
    },
    // 点击日历单元格
    onChange(date, e) {
      if (this.isDisabled(date)) {
        // 禁止点击
        e.stopPropagation();
      } else {
        // 根据点击的单元格，得到日期，找到对应的日期商品信息，未找到为''
        let currentDate = this.getCurrentDateFormatted(date);
        this.currentPriceData = this.list.find(
          (item) => item.start_date == currentDate
        );
        let originalPrice = this.currentPriceData
          ? this.currentPriceData.online_price
          : "";
        Object.assign(this.form, { currentDate, originalPrice });

        // Object.assign(this.form, { currentDate });

        // 打开内层弹出层，进行编辑产品价格
        this.innerVisible = true;
      }
    },
    // 日期跟价格进行对应
    price(val) {
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];
        // 检查对象的day属性是否与目标日期相匹配
        if (item.start_date === val) {
          // 如果匹配，则返回该对象的price属性值
          return item.online_price;
        }
      }
      // 如果没有找到匹配项，则返回空字符串
      return "";
    },
    // 是否禁用单元格
    isDisabled(date) {
      return (
        new Date(this.getCurrentDateFormatted(date)).getTime() <
        new Date(this.getCurrentDateFormatted()).getTime()
      );
    },
    // 格式化时间格式
    getCurrentDateFormatted(date = new Date()) {
      const now = date;
      const year = now.getFullYear();
      let month = now.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的，所以我们需要加 1
      let day = now.getDate();

      // 如果月份或者日是一位数，前面补零
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;

      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style scoped>
::v-deep .el-dialog {
  min-width: 500px !important;
}
::v-deep .el-dialog__header {
  background-color: #f7f8fb;
  font-weight: bold;
}

::v-deep .el-calendar-day {
  padding: 0 !important;
}
::v-deep .customDialog > .el-dialog__body {
  padding: 0;
}
::v-deep .el-calendar-table .el-calendar-day {
  height: 60px;
}
/* ::v-deep .is-today .date-txt{
   color: #1989fa;
} */
.date-txt {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  /* color:#606266 */
}
.date-txt.disabled {
  background-color: #f5f7fa;
  opacity: 1;
  cursor: not-allowed;
  color: #c0c4cc;
}
.disabled .price {
  color: #ccc;
}
.price {
  height: 12px;
  color: #ff8e3d;
}
.is-selected {
  color: #1989fa;
}
/* .is-selected.date-txt {
  color: #1989fa;
} */
</style>
