<template>
  <div id="app">
    <left-nav-bar />
    <main-tab-nav />
    <div class="home">
      <div class="dataview">
          <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import MainTabNav from "@components/common/maintabnav/MainTabNav";
import LeftNavBar from "@components/common/leftnavbar/LeftNavBar";

export default {
  name: "App",
  components: {
    MainTabNav,
    LeftNavBar,
  },
  created: function () {
    this.$router.push("/");
  },
  watch: {
    '$store.state.userInfo': function (newVal, oldVal) {
      // 修改titlt
      document.title= newVal.newName?newVal.newName:newVal.sys;
    }
  },
  mounted(){
    let that = this;
    window.addEventListener("resize",this.$_.throttle(function(e){that.setWorH(e)},200))
    console.log(this)
    //左边侧边栏自动缩放
    this.$store.commit("setWindowWidth", document.body.clientWidth);
    window.onresize = () => {
      return (() => {
        this.$store.commit("setWindowWidth", document.body.clientWidth);
      })();
    };
    window.addEventListener('popstate', this.handleRefresh);

  },
  methods:{

    //设置宽高
    setWorH(e){
      this.$store.commit('setWH',{Win_width:e.target.innerWidth,Win_height:e.target.innerHeight})
    },
    handleRefresh(){
      this.$store.commit("setcurrentNav", { index:'home', name:'首页' });
      this.$bus.$emit("popstate","home");
    }
  }
};
</script>

<style>
@import "assets/css/base.css";
</style>
