<template>
  <div>
    <main-tab-bar
      ref="tabbar"
      :tabitem="tabitem"
      :activename="activename"
      :loading="showTabLoading"
      :is-custom-close-tab="true"
      @handleTabClick="handleTabClick"
      @closeTab="handleCloseTab"
    >
      <!-- 固定的插槽名称需要和tabitem的label对应  v-if="tableColumn.length" -->
      <div :slot="tssp">
        <!-- 头部区域 -->
        <el-row type="flex" class="row-bg top-box" justify="space-between">
          <el-col :span="6">
            <el-button size="mini" type="primary" @click="handleAddData">新增</el-button>
          </el-col>
          <el-form @submit.native.prevent :inline="true" class="search">
            <el-form-item label="名称：">
              <el-input size="mini" clearable v-model="searchKeyVal" @keyup.enter.native="onQuery" />
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="onQuery">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <!-- 列表内容 -->
        <table-comp
          :loading="showTableLoading"
          :table-data="tableData"
          :table-column="tableColumn"
          :pageno="pageNo"
          :pagenum="pageNum"
          :total="tableTotal"
          :hotels="hotels"
          :selectType="selectType"
          :show-batch-del="true"
          :show-export="false"
          @editRow="handleEditRow"
          @delRow="handleDelRow"
          @batchDel="batchDel"
          @handlePageChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
          @openScenicChildTab="openScenicChildTabHandle"
        ></table-comp>
      </div>

      <!-- 子系列表格 -->
      <div :slot="openScenicChildTabName" >
        <el-row type="flex" class="row-bg top-box" justify="space-between">
          <el-col :span="6">
            <el-button size="mini" type="primary" @click="handleAddData" >新增</el-button>
          </el-col>
          <el-form @submit.native.prevent :inline="true" class="search">
            <el-form-item label="名称：">
              <el-input size="mini" clearable v-model="searchKeyVal" @keyup.enter.native="onQuery"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="onQuery">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <table-comp
          :loading="showTableLoading"
          :table-data="tableData2"
          :table-column="tableColumn2"
          :pageno="pageNo2"
          :pagenum="pageNum2"
          :total="tableTotal2"
          :hotels="hotels"
          :selectType="selectType"
          :show-batch-del="true"
          :show-export="false"
          @editRow="handleEditRow"
          @delRow="handleDelRow"
          @batchDel="batchDel"
          @handlePageChange="handlePageChange"
          @handleSizeChange="handleSizeChange"
        ></table-comp>
      </div>

      <!-- 编辑页插槽 -->
      <div :slot="editPage" v-if="this.form">
        <from-data
          :show-filed-data="showFieldData"
          :selectdata="selectDataMap"
          :is-edit="isEdit"
          :form="form"
          :rules="rules"
          @closetForm="closetForm"
          @submitForm="submitForm"
        ></from-data>
      </div>
    </main-tab-bar>
  </div>
</template>

<script>
//标签页组件
import MainTabBar from "@components/content/maintabbar/MainTabBar";
//表格组件
import TableComp from "@components/content/tablecomp/TableComp";
//表单组件
import FromData from "./childecomponent/FromData";
import ImageUpload from "./childecomponent/ImageUpload.vue";

export default {
  components: {
    MainTabBar,
    TableComp,
    FromData,
    ImageUpload,
  },
  data() {
    return {
      tabitem: [
        {
          name: "tssp",
          label: "特色商品",
        }
      ],
      activename: "tssp",
      prevActiveName: null,

      showTabLoading: false, //标签页加载中
      showTableLoading: false, //表格加载中

      fieldList: [], // 字段列表

      searchKeyVal: "", //搜索关键字

      tableColumn: [], // 表格的栏目
      tableColumn2: [], // 表格的栏目

      pageNo: 1,
      pageNum: 20,
      tableData: [], // 表格数据
      tableTotal: [], // 表格数据总数

      pageNo2: 1,
      pageNum2: 20,
      tableData2: [], // 表格数据
      tableTotal2: [], // 表格数据总数

      hotels: [],
      selectType: [],

      showFieldData: [], //显示的表单字段
      fieldTypeMap: {}, // 字段类型映射
      selectDataMap: {}, // 下拉数据映射
      isEdit: false, // 是否是编辑状态
      baseForm: null,
      form: null,
      rules: {},

      openScenicChildTabName: "",
      openScenicChildTabId: null,
    };
  },
  computed: {
    tssp() {
      return this.tabitem[0].label;
    },
    editPage() {
      let target = this.tabitem.find(item => item.name === "editpage");
      return target ? target.label : null;
    },
  },
  created() {
    // 接收事件总线
    // 单图
    this.$bus.$on("imgUploaded", this.imgUploaded);
    this.$bus.$on("imgRemoved", this.imgRemoved);
  },
  mounted() {
    this.getResourcefield();
    this.queryDataOfResource();
  },
  methods: {
    // 点击标签页
    handleTabClick(tab) {
      if(tab.name === this.activename) return;
      if(this.activename !== "editpage") this.searchKeyVal = "";

      this.activename = tab.name;
      this.$refs.tabbar.activeName = tab.name;
    },
    // 关闭标签页
    handleCloseTab(name) {
      this.baseForm = null;
      this.form = null;

      let index = this.tabitem.findIndex(item => item.name == name);
      this.tabitem.splice(index, 1);

      if(name === "scenic-child") {
        this.openScenicChildTabId = null;
        this.openScenicChildTabName = "";
      }

      this.activename = this.prevActiveName || "tssp";
      this.$refs.tabbar.activeName = this.prevActiveName || "tssp";

      this.prevActiveName = null;
    },

    // 点击查询按钮
    onQuery() {
      this.queryDataOfResource(this.getQueryParams());
    },
    getQueryParams() {
      let params = {};
      let searchKey = this.showFieldData[0].en_name
      params[searchKey] = this.searchKeyVal;
      if(this.activename === "scenic-child") {
        params["parent_id"] = this.openScenicChildTabId;
      }
      return params;
    },

    // 点击添加按钮
    handleAddData() {
      this.prevActiveName = this.activename;

      this.form = this.showFieldData.reduce((prev,item) => {
        if(item.en_name){
          prev[item.en_name] = item.show_type == "2" ? 1 : "";
        }
        return prev;
      }, {});

      this.isEdit = false;
      this.activename = "editpage";
      this.$refs.tabbar.activeName = "editpage";

      let targetIndex = this.tabitem.findIndex(item => item.name == this.activename);
      if(targetIndex > -1){
        this.tabitem[targetIndex].label = "新增";
      }else{
        this.tabitem.push({
          name: "editpage",
          label: "新增",
          closable: true,
        });
      }
    },
    // 点击编辑
    handleEditRow(row, field) {
      this.prevActiveName = this.activename;

      this.baseForm = JSON.parse(JSON.stringify(row));
      this.form = JSON.parse(JSON.stringify(row));

      if (this.form.images) {
        this.form.images = JSON.parse(this.form.images) || [];
      }

      this.isEdit = true;
      this.activename = "editpage";
      this.$refs.tabbar.activeName = "editpage";

      let targetIndex = this.tabitem.findIndex(item => item.name == this.activename);
      if(targetIndex > -1){
        this.tabitem[targetIndex].label = row[field] + "-编辑";
      }else{
        this.tabitem.push({
          name: "editpage",
          label: row[field] + "-编辑",
          closable: true,
        });
      }
    },
    // 点击删除
    handleDelRow(row) {
      this.deleteDataOfResource(row.row_id,() => {
        if(this.form && row.row_id == this.form.row_id) {
          handleCloseTab("editpage");
        }
      });
    },
    // 批量删除
    batchDel(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = row.map(item => item.row_id);
        this.deleteDataOfResource(ids.toString(), () => {
          if(this.form && ids.find(id => id === this.form.row_id)) {
            handleCloseTab("editpage");
          }
        });
      }).catch(() => {});
    },

    getResourcefield() {
      this.showTabLoading = true;

      this.$Request({
        method: "get",
        url: this.$Api.getResourcefield,
        params: {
          directory_code: "commodity",
        },
      }).then(res => {
        this.fieldList = res.data.list;

        let tableColumn = [];
        let showFieldData = [];

        this.fieldList.map(item => {
          if(item.show_type != 0) {
            showFieldData.push(item);
            this.fieldTypeMap[item.en_name] = item.type;
            this.setSelectDataMap(item);
          }
          if(item.en_name === "latitude"){
            showFieldData.push({
              name: "",
              show_type: 11,
            });
          }
          if(item.is_intable == 1) {
            let isFirst = tableColumn.length ? false : true;
            tableColumn.push(this.getTableColItem(item, isFirst))
          }
        })
        
        this.tableColumn = JSON.parse(JSON.stringify(tableColumn));
        this.tableColumn.push(this.getTableColLastItem());
        this.tableColumn2 = JSON.parse(JSON.stringify(tableColumn));
        this.tableColumn2.push(this.getTableColLastItem2());

        this.showFieldData = showFieldData;
        this.setRules()
      }).finally(() => {
        this.showTabLoading = false;
      })
    },
    getTableColItem({name,en_name,show_type}, isFirst) {
      let column = {
        label: name,
        prop: en_name,
        show_type: show_type
      }
      if(isFirst){
        column.fristEnten = true; //首列点击
        column.func = "editRow";  //点击的方法
      }
      return column;
    },
    getTableColLastItem() {
      return {
        isAction: true,
        fixed: "right",
        label: "操作",
        //操作的按钮与方法
        actionItem: [
          {
            text: "编辑", //按钮的文本
            icon: "el-icon-edit",
            type: "primary", //按钮类型
            func: "editRow", //按钮的方法
          },
          {
            text: "查看子系列", //按钮的文本
            icon: "el-icon-search",
            type: "primary", //按钮类型
            func: "openScenicChildTab",
          },
        ],
        //带确认的按钮
        popconfirmitem: [
          {
            text: "删除",
            icon: "el-icon-delete",
            func: "delRow",
            title: "确定删除这条数据吗？",
          },
        ],
      }
    },
    getTableColLastItem2() {
      return {
        isAction: true,
        fixed: "right",
        label: "操作",
        //操作的按钮与方法
        actionItem: [
          {
            text: "编辑", //按钮的文本
            icon: "el-icon-edit",
            type: "primary", //按钮类型
            func: "editRow", //按钮的方法
          }
        ],
        //带确认的按钮
        popconfirmitem: [
          {
            text: "删除",
            icon: "el-icon-delete",
            func: "delRow",
            title: "确定删除这条数据吗？",
          },
        ],
      }
    },
    setSelectDataMap(item) {
      if(item.associate){
        // console.log(item);
        this.queryDataOfResourceForSelect(item.en_name, item.associate);
      }else{
        if(item.show_type == 2){
          this.selectDataMap[item.en_name] = [
            { name: "是", row_id: 1 },
            { name: "否", row_id: 0 }
          ];
        }else if(item.show_type == 4 && item.en_name == "sex"){
          this.selectDataMap[item.en_name] = [
            { name: "男", row_id: 1 },
            { name: "女", row_id: 0 }
          ];
        }
      }
    },
    // 查询资源对应数据用于选择框
    queryDataOfResourceForSelect(en_name, associate) {
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params: {
          pageNo: 1,
          pageNum: 100,
          directory_code: associate,
        }
      }).then((res) => {
        if (res.errcode == 0) {
          this.selectDataMap[en_name] = res.data;
        }
      })
    },
    setRules() {
      this.rules = {
        name_chn: [{
          required: true,
          message: "请输入名称",
          trigger: "blur",
        }]
      };
    },

    // 分页
    handlePageChange(val) {
      if(this.activename === "scenic-child") {
        this.pageNo2 = val;
      }else{
        this.pageNo = val;
      }

      this.queryDataOfResource(this.getQueryParams());
    },
    // 切换分页大小
    handleSizeChange(val) {
      if(this.activename === "scenic-child") {
        this.pageNum2 = val;
      }else{
        this.pageNum = val;
      }

      this.queryDataOfResource(this.getQueryParams());
    },

    // 点击关闭按钮
    closetForm() {
      this.handleCloseTab("editpage")
    },
    // 点击保存
    submitForm(formData) {
      if (this.isEdit) {
        this.modifyDataOfResource(formData, () => {
          if(this.prevActiveName === "scenic-child"){
            this.pageNo2 = 1;
          }else{
            this.pageNo = 1;
          }
          this.handleCloseTab("editpage");
          this.queryDataOfResource(this.getQueryParams());
        });
      } else {
        this.addDataOfResource(formData,() => {
          if(this.prevActiveName === "scenic-child"){
            this.pageNo2 = 1;
          }else{
            this.pageNo = 1;
          }
          this.handleCloseTab("editpage");
          this.queryDataOfResource(this.getQueryParams());
        });
      }
    },
    //处理上传前的表单 删除create_time
    clearSubmitData(fromData) {
      delete fromData.create_time;
      return fromData;
    },
    
    openScenicChildTabHandle(row) {
      this.prevActiveName = this.activename;

      let targetIndex = this.tabitem.findIndex((item) => item.name === "scenic-child");
      
      this.openScenicChildTabName = row.name_chn + "-特色商品";
      this.openScenicChildTabId = row.row_id;

      if (targetIndex > -1) {
        this.tabitem[targetIndex].label = this.openScenicChildTabName;
      } else {
        this.tabitem.push({
          name: "scenic-child",
          label: this.openScenicChildTabName,
          closable: true,
        })
      }

      this.activename = "scenic-child";
      this.$refs.tabbar.activeName = "scenic-child";
      
      this.queryDataOfResource();
    },
    

    //单图上传
    imgUploaded(res, name, source) {
      this.$set(this.form, name, res.path);
    },
    //单图删除
    imgRemoved(name, source) {
      this.form[name] = "";
    },


    // 获取表格数据
    queryDataOfResource(data = {}) {
      this.showTableLoading = true;

      let params = {
        data,
        directory_code: "commodity",
      };

      if(this.activename === "tssp"){
        params.pageNo = this.pageNo;
        params.pageNum = this.pageNum;
        params.data["parent_id"] = 0;
      }else if(this.activename === "scenic-child"){
        params.pageNo = this.pageNo2;
        params.pageNum = this.pageNum2;
        params.data["parent_id"] = this.openScenicChildTabId;
      }

      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      }).then((res) => {
        if(this.activename === "tssp"){
          this.tableData.length = 0;
          this.tableData = res.data;
          this.tableTotal = res.total
        }else if(this.activename === "scenic-child"){
          this.tableData2.length = 0;
          this.tableData2 = res.data;
          this.tableTotal2 = res.total
        }
        
      }).finally(() => {
        this.showTableLoading = false;
      })
    },
    // 删除表格数据
    deleteDataOfResource(row_id, callback){
      this.$Request({
        method: "get",
        url: this.$Api.deleteDataOfResource,
        params:{
          data: { row_id },
          directory_code: "commodity",
        },
      }).then((res) => {
        if(res.errCode == 0){
          this.$message({ message: "删除成功", type: "success" });
          this.queryDataOfResource(this.getQueryParams());
          callback && callback();
        }
      }).catch(() => {});
    },
    // 修改数据
    modifyDataOfResource(formData, callback) {
      formData = Object.keys(formData).reduce((prev, key) => {
        if(formData[key] !== "" || this.baseForm[key] !== undefined) prev[key] = formData[key];
        return prev;
      }, {});

      this.$Request({
        method: "post",
        url: this.$Api.modifyDataOfResource,
        data: { 
          data: formData, 
          directory_code: "commodity"
        },
      })
      .then((result) => {
        if (result.errCode == 0) {
          this.$message({ message: "修改成功", type: "success" });
          callback && callback()
        }
      }).catch(() => {});
    },
    // 添加数据
    addDataOfResource(formData, callback) {
      formData = Object.keys(formData).reduce((prev, key) => {
        if(formData[key] !== "") prev[key] = formData[key];
        return prev;
      }, {});

      if(this.prevActiveName === "tssp"){
        formData["parent_id"] = 0;
      }else if(this.prevActiveName === "scenic-child"){
        formData["parent_id"] = this.openScenicChildTabId;
      }

      this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: { 
          data: formData, 
          directory_code: "commodity"
        },
      }).then((res) => {
        if (res.errCode == 0) {
          this.$message({ message: "新增成功", type: "success"});
          callback && callback()
        }
      }).catch(() => {});
    },
  },
  beforeDestroy() {
    this.$bus.$off("imgUploaded", this.imgUploaded);
    this.$bus.$off("imgRemoved", this.imgRemoved);
  },
};
</script>

<style scoped lang="scss">
.radio {
  line-height: 25px;
  position: absolute;
  margin-left: 80px;
}
.hotel_config {
  min-width: 370px;
  margin-top: 15px;
  display: flex;
  margin-left: 28%;
  margin-right: 0%;
  .el-form-item {
    display: flex;
    align-items: center;
    /deep/.el-form-item__content {
      width: 120px;
    }
    &:nth-child(1) {
      margin-left: -55px;
      /deep/.el-form-item__content {
        width: 160px;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}

@media (max-width: 1280px) {
  // .hotel_config{
  //   margin-right: 1.5%;
  // }
  // .search{
  //   margin-right: -1%;
  // }
}

.search {
  min-width: 340px;
}

/deep/.el-tabs--border-card > .el-tabs__content {
  // padding: 0 !important;
  // padding-top: 14px !important;
  // padding-left: 15px !important;
  // padding-right: 15px !important;
  padding: 0 15px !important;
  box-sizing: border-box;
}

/deep/.el-tabs--border-card > .el-tabs__content #pane-sjy {
  padding-right: 15px !important;
}

/deep/.search .el-button--mini {
  position: relative;
  left: 10px;
}

.top-box {
  margin-top: 15px;
}
</style>