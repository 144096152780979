const stream = require('stream')
const Minio = require('minio')
import store from '../store/index'

// let rootName = store.state.userInfo.account
// console.log('000',rootName);
import {
  getNowTime
} from "@common/publicmethods"

/**
 * minio主要有三种文件上传方法
 * 1. putObject 从一个stream/Buffer中上传一个对象。
 * 2. fPutObject 上传文件。（貌似使用有点问题）
 * 3. presignedPutObject 生成一个给HTTP PUT请求用的presigned URL。
 *    浏览器/移动端的客户端可以用这个URL进行上传。
 */



// 连接minio
const minioClient = new Minio.Client({
  endPoint: 'minio.xyabcd.com', // 地址
  // port: 80, // 端口号，若地址为类似test.minio.com,就不必写端口号
  useSSL: true, // 是否使用ssl
  // accessKey: 'AKIAIOSFODNN7EXAMPLE', // 登录的key
  // secretKey: 'wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY',
  accessKey: 'admin', // 登录的key
  secretKey: 'admin@winyeahs'
});

/**
 *
 * @export 上传文件（stream流方法）
 * @param {*} backetName String 存储桶名字
 * @param {*} fileObj Object 文件对象
 * @param {*} path String 文件存储路径
 * @param {*} vm Object 调用该方法的页面的this
 * @return {*} null
 */
export function uploadMinIo(backetName = 'amobiletour', fileObj, path, vm) {
  if (
    fileObj
  ) {
    let file = fileObj;
    console.log("file", file);
    //判断是否选择了文件
    if (file == undefined) {
      //未选择
    } else {
      //选择
      // 获取文件类型及大小
      // 给文件名加上当前时间
      const fileName = getNowTime("time") + file.name;
      const fileDate = getNowTime("fileDate") // 生成以日为分类的文件夹
      const mineType = file.type;
      const fileSize = file.size;
      console.log("fileName", fileName);
      //参数
      let metadata = {
        "content-type": mineType,
        "content-length": fileSize,
      };
      //判断储存桶是否存在
      minioClient.bucketExists(backetName, function (err) {
        console.log("判断储存桶是否存在");
        if (err) {
          if (err.code == "NoSuchBucket")
            return console.log("bucket does not exist.");
          return console.log(err);
        }
        //准备上传
        let reader = new FileReader();
        console.log(reader);
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          //读取完成触发，无论成功或失败
          console.log("ee", e);
          const dataurl = e.target.result;
          //base64转blob
          const blob = toBlob(dataurl);
          //blob转arrayBuffer
          let reader2 = new FileReader();
          reader2.readAsArrayBuffer(blob);
          reader2.onload = function (ex) {
            //定义流
            let bufferStream = new stream.PassThrough();
            //将buffer写入

            bufferStream.end(Buffer.from(ex.target.result));
            //上传
            minioClient.putObject(
              backetName,
              `${path}/${fileDate}/${fileName}`,
              bufferStream,
              fileSize,
              metadata,
              function (err, etag) {
                // console.log("dddddd");
                if (err == null) { // 为空则代表上传成功
                  let res = {
                    path: `http://192.168.0.226:30014/${backetName}/${path}/${fileDate}/${fileName}`,
                    result: 1,
                  };
                  // 成功生成url后调用
                  // 调用传进来的this的的方法，然后通过该方法把成功事件发送出去
                  vm.handleAvatarSuccess(res, vm.filedname);
                  vm.fileName = fileName;
                  vm.$message({
                    message: "上传成功！",
                    type: "success",
                  });
                  // 由于minio设置了永久链接，该生成临时url的方法就不再使用
                  // minioClient.presignedGetObject(
                  //   "medialibrary",
                  //   `archive${a}${fileName}`,
                  //   24 * 60 * 60,
                  //   function (err, presignedUrl) {
                  //     if (err) return console.log(err);
                  //     let res = {
                  //       path: presignedUrl,
                  //       result: 1,
                  //     };
                  //     // 成功生成url后调用
                  //     vm.handleAvatarSuccess(res, vm.filedname);
                  //     vm.fileName = fileName;
                  //     vm.$message({
                  //       message: "上传成功！",
                  //       type: "success",
                  //     });
                  //     console.log("链接：",presignedUrl);
                  //   }
                  // );
                }
              }
            );
          };
        };
      });
    }
  } else {
    this.$message({
      message: "文件类型错误！",
      type: "error",
    });
  }
}
/**
 *
 * @export base64转blob
 * @param {*} base64Data Object base64数据
 * @return {*} blob
 */
//base64转blob
export function toBlob(base64Data) {
  let byteString = base64Data
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = window.atob(base64Data.split(',')[1]) // base64 解码
  } else {
    byteString = unescape(base64Data.split(',')[1])
  }
  // 获取文件类型
  let mimeString = base64Data.split(';')[0].split(":")[1] // mime类型

  // ArrayBuffer 对象用来表示通用的、固定长度的原始二进制数据缓冲区
  // let arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
  // let uintArr = new Uint8Array(arrayBuffer) // 创建视图

  let uintArr = new Uint8Array(byteString.length) // 创建视图

  for (let i = 0; i < byteString.length; i++) {
    uintArr[i] = byteString.charCodeAt(i)
  }
  // 生成blob
  const blob = new Blob([uintArr], {
    type: mimeString
  })
  // 使用 Blob 创建一个指向类型化数组的URL, URL.createObjectURL是new Blob文件的方法,可以生成一个普通的url,可以直接使用,比如用在img.src上
  return blob
}



// 两个参数，存储桶的名字，要上传文件的名字。例如test.txt
/**
 *
 * @export 获取上传链接（url方法）获取上传的url;会生成一个带预签名的链接
 * @param {*} bucket String 存储桶的名字
 * @param {*} totalFolderName String 总文件夹名字 例如:imagelibrary
 * @param {*} fileName String 文件名字
 * @return {*} Promise
 */


export function getUploadUrl(totalFolderName, fileName) {
  let rootName = store.state.userInfo.account
  rootName == 'superadmin' ? rootName = 'winyeahs' : rootName = store.state.userInfo.account
  console.log("rootName---------", rootName);
  let defaultPath = getNowTime("fileDate"); // 添加默认的以日为分类的文件夹
  return minioClient.presignedPutObject('amobiletour', `${rootName}/${totalFolderName}/${defaultPath}/${fileName}`)
}

// 通过url上传
/**
 *
 * @export 上传文件（url方法）通过获取带预签名的链接上传
 * @param {*} url String 预签名链接
 * @param {*} data Object 文件对象
 * @return {*} Promise
 */
export function uploadByUrl(url, data) {
  return fetch(url, {
    mode: "cors", // 解决跨域
    headers: {
      Accept: "application/json,text/plain,/",
      // 'Access-Control-Allow-Origin':'PUT'
    },
    method: "PUT",
    body: data,
  });
}

/**
 * @export 从存储桶中删除一个对象
 * @param {*} bucketName 存储桶的名字
 * @param {*} objectPathAndName 要删除对象的路径（注意：要写对路径，紧跟存储桶后面的路径）
 * @description 
 * @author 
 * @version V1.0.0
 * @return {*} Promise
 */
export function removeObject(objectPathAndName) {
  return minioClient.removeObject('amobiletour', objectPathAndName)
}