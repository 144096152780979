import * as XLSX from 'xlsx/xlsx.mjs';


//时间格式化
/**
 *
 *
 * @export 时间格式化
 * @param {*} dateStr 时间戳或者时间字符串
 * @param {string} [fmt='yyyy-MM-dd hh:mm:ss']
 * @return {*} 
 */


export function formatDate(dateStr, fmt = 'yyyy-MM-dd hh:mm:ss') {
    if (!dateStr) return;
    const date = new Date(dateStr);
    const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
        }
    }
    return fmt;
}

/**
 * @export 获取当前时间并格式化
 * @param {*} isDate Boolean 为真返回时分秒； 为"time"返回不带连接符的时分秒；为"fileDate"返回文件夹日期
 * @return {*} yyyy-MM-dd hh:mm:ss
 */
export function getNowTime(isDate = false) {
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth(); //得到月份
    var date = now.getDate(); //得到日期
    var hh = now.getHours();
    var mm = now.getMinutes();
    var ss = now.getSeconds();
    var hour = " " + hh + ":" + mm + ":" + ss + ""; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
    var hours = hh + '' + mm + '' + ss + "-";
    month = month + 1;
    month = month.toString().padStart(2, "0");
    date = date.toString().padStart(2, "0");
    var defaultDate = `${year}-${month}-${date}${hour}`;
    if (isDate) {
        var defaultDate = `${year}-${month}-${date}`;
    }
    if (isDate == 'time') {
        var defaultDate = `${year}${month}${date}${hours}`;
    }
    if (isDate == 'fileDate') {
        var defaultDate = `${year}${month}${date}`;
    }
    return defaultDate;
}
/**
 *  验证失败定位
 * @returns 
 */
export function failCheckPosition() {
    setTimeout(() => {
        var isError = document.getElementsByClassName("is-error");
        isError[0].querySelector('input').focus();
    }, 100);
    return false;
}
/**
 * 获取星期
 * @returns 
 */
export function getWeek() {
    let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
    ];
    let wk = new Date().getDay();
    return weeks[wk];
}

/**
 * @export 获取图片路径
 * @param {*} 参数1
 * @param {*} 参数2
 * @description 
 * @author 
 * @version V1.0.0
 * @return {*} 
 */
// 
export function getImgName(url, num = 3) {
    if (url) {
        let index1 = findCharIndex(url, '/', num)
        // console.log("index1", index1);
        let index2 = url.lastIndexOf("?");
        // console.log("index2", index2);
        url =
            index2 != -1 ?
            url.substring(index1 + 1, index2) :
            url.substring(index1 + 1, url.length);
        return url;
    }
}

/**
 * @export 获取某个字符在一串字符中第n次出现的位置(注意:从0开始计数)
 * @param {*} 参数1
 * @param {*} 参数2
 * @description 
 * @author 
 * @version V1.0.0
 * @return {*} 
 */
// 

function findCharIndex(str, cha, num) {
    let x = str.indexOf(cha);
    for (let i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
    }
    return x;
}


export function filterParams(obj) {
    let _newPar = {};
    for (let key in obj) {
      //如果对象属性的值不为空，就保存该属性（这里做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。
      if ((obj[key] === 0 ||obj[key] === false|| obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
        _newPar[key] = obj[key];
      }
    }
    return _newPar;
  }

  //获取完整的日期

  export function getMonths(){
    var date=new Date;
    var year=date.getFullYear(); 
    var month=date.getMonth()+1;
    month =(month<10 ? "0"+month:month); 
    var mydate = (year.toString()+month.toString());
    return mydate
  }


  //获取每月有多少天
  export function mGetDate(){
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth()+1;
    var d = new Date(year, month, 0);
    return d.getDate();
}

export function getMonthDay(year, month) {
    let days = new Date(year, month + 1, 0).getDate()
    return days
  }


  export function getCountDays(month) {
    var curDate = new Date();
    /* 获取当前月份 */
   
   /*  生成实际的月份: 由于curMonth会比实际月份小1, 故需加1 */
   curDate.setMonth(month);
   /* 将日期设置为0 */
   curDate.setDate(0);
   /* 返回当月的天数 */
   return curDate.getDate();
}


export function doHandleMonth() {
    var myDate = new Date();
    var tMonth = myDate.getMonth();
 
    var m = tMonth + 1;
    if (m.toString().length == 1) {
        m = "0" + m;
    }
    return `${myDate.getFullYear()}-${m}`;
}

export function openDownloadDialog(url, saveName)
{
	if(typeof url == 'object' && url instanceof Blob)
	{
		url = URL.createObjectURL(url); // 创建blob地址
	}
	var aLink = document.createElement('a');
	aLink.href = url;
	aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
	var event;
	if(window.MouseEvent) event = new MouseEvent('click');
	else
	{
		event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
	}
	aLink.dispatchEvent(event);
}
// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
export function sheet2blob(sheet, sheetName) {
	sheetName = sheetName || 'sheet1';
	var workbook = {
		SheetNames: [sheetName],
		Sheets: {}
	};
	workbook.Sheets[sheetName] = sheet;
	// 生成excel的配置项
	var wopts = {
		bookType: 'xlsx', // 要生成的文件类型
		bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
		type: 'binary'
	};
	var wbout = XLSX.write(workbook, wopts);
	var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
	// 字符串转ArrayBuffer
	function s2ab(s) {
		var buf = new ArrayBuffer(s.length);
		var view = new Uint8Array(buf);
		for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	}
	return blob;
}

export function uuid() {
    return 'xxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 3) | 0;
    //   var v = c == 'x' ? r : (r & 0x3) | 0x8;
      return r;
    });
  }
  

 