<template>
  <!-- 表格组件 -->
  <div class="tablecontaner">
    <el-table
      ref="table"
      height="calc(100vh - 218px)"
      @selection-change="handleSelectionChange"
      :row-class-name="tableRowClassName"
      tooltip-effect="dark"
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      class="tablepad"
      :row-style="{ height: '36px' }"
      :cell-style="{ padding: '0px' }"
      :header-row-style="{ height: '20px' }"
      v-if="tableColumn.length != 0"
    >
      <el-table-column type="selection" width="55"></el-table-column>

      <el-table-column
        v-for="item in tableColumn.filter((item) => !item.isAction)"
        :key="item.row_id"
        :align="item.isAction ? 'left' : 'center'"
        :prop="item.prop"
        :label="item.label"
        min-width="150"
        show-overflow-tooltip
        :fixed="item.fixed"
        :width="item.isAction ? '100' : ''"
      >
        <template slot-scope="scope">
          <!-- 序号显示 -->
          <span v-if="item.index">
            <el-link
              v-if="item.fristEnten"
              @click="$emit(item.func, scope.row, getEnterFiled())"
            >
              {{ scope["$index"] + 1 }}
            </el-link>

            <span v-else>
              {{ scope["$index"] + 1 }}
            </span>
          </span>
          <!-- 操作列显示 -->

          <!-- 星级 针对特殊显示的处理 一般不用 （云野大数据平台使用） -->
          <span v-else-if="item.level">
            <el-rate
              :value="scope.row[item.prop]"
              disabled
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </span>
          <!-- show_type==2时数字0和1转为是或否 -->
          <span v-else-if="item.show_type == 2">
            {{ scope.row[item.prop] == 1 ? "是" : "否" }}
          </span>
          <!-- 默认显示 -->
          <span
            :style="
              !item.condition
                ? item.style
                : istrue(item.condition, scope.row)
                ? item.style
                : ''
            "
            v-else
          >
            <!-- 设置第一行选择 进入编辑 fristEnten：true func为执行的方法-->
            <el-link
              v-if="item.fristEnten"
              @click="$emit(item.func, scope.row, getEnterFiled())"
            >
              {{ scope.row[item.prop] }}
            </el-link>
            <!-- 设置状态 如1表示已启用 2表示未启用 传来一个map filterData 就会根据当前值去map中查询 -->
            <span v-else-if="item.map">
              {{ scope.row[item.prop] | filterData(item.map) }}
            </span>
            <!-- 默认显示 -->
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </span>
        </template>
      </el-table-column>

      <!-- 操作 -->
      <el-table-column
        v-for="item in tableColumn.filter((item) => item.isAction)"
        :key="item.row_id"
        align="left"
        :prop="item.prop"
        :label="item.label"
        :width="(item.actionItem.length+1)*33"
        show-overflow-tooltip
        :fixed="item.fixed"
      >
        <template slot-scope="scope">
          <!-- 操作列显示 -->
          <span>
            <el-button
              class="btn-icon"
              v-for="actionItem in item.actionItem"
              :key="actionItem.text"
              @click="onClickHandler(actionItem,scope)"
              type="text"
              size="small"
              v-show="istrue(actionItem.condition, scope.row)"
            >
              <el-tooltip
                effect="dark"
                :enterable="false"
                :content="actionItem.text"
                placement="top"
              >
                <i :class="actionItem.icon"></i>
              </el-tooltip>
            </el-button>

            <!-- 是否删除 -->
            <el-popconfirm
              v-for="actionItem in item.popconfirmitem"
              :key="actionItem.text"
              :title="actionItem.title"
              @confirm="$emit(actionItem.func, scope.row)"
            >
              <el-button
                class="btn-icon"
                slot="reference"
                type="text"
                size="small"
                v-show="istrue(actionItem.condition, scope.row)"
                style="margin-left: 10px"
              >
                <el-tooltip
                  effect="dark"
                  :enterable="false"
                  :content="actionItem.text"
                  placement="top"
                >
                  <i :class="actionItem.icon"></i>
                </el-tooltip>
              </el-button>
            </el-popconfirm>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格分页 -->
    <div class="footer" v-if="tableColumn.length != 0">
      <div>
        <el-button
          :disabled="!selectRows.length"
          type="primary"
          v-if="showBatchDel"
          size="mini"
          @click="$emit('batchDel', selectRows)"
          >批量删除</el-button
        >
      </div>
      <div>
        <el-pagination
          background
          :page-size="pageNum"
          :page-sizes="[20, 30, 40, 50]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagetotal"
          :current-page.sync="pageNo"
          @current-change="
            (val) => {
              $emit('handlePageChange', val);
            }
          "
          @size-change="
            (val) => {
              $emit('handleSizeChange', val);
            }
          "
        >
        </el-pagination>
      </div>
      <div>
        <el-button type="primary" v-if="showExport" size="mini">导出</el-button>
      </div>
    </div>
    <CustomCalender v-if="currentNav=='ticket'" ref="calender"></CustomCalender>
  </div>
</template>

<script>
import CustomCalender from "../../CustomCalender/index";
export default {
  components: {
    CustomCalender,
  },
  props: [
    "tableData",
    "tableColumn",
    "pagenum",
    "pageno",
    "total",
    "showBatchDel",
    "showExport",
    "loading",
    "hotels",
    "selectType",
  ],
  watch: {
    total(val) {
      this.pagetotal = val;
    },
    pagenum(val) {
      this.pageNum = val;
    },
    pageno(val) {
      this.pageNo = val;
    },

    tableData: {
      deep: true,
      handler: function() {
        this.matchingType();
        this.matchingHotels();
      },
    },
    hotels: {
      deep: true,
      handler: function() {
        this.matchingType();
      },
    },
    selectType: {
      deep: true,
      handler: function() {
        this.matchingHotels();
      },
    },
  },
  mounted() {
    this.matchingType();
    this.matchingHotels();
  },

  computed: {
    // totals() {
    //  return this.total
    // }
  },

  data() {
    return {
      pagetotal: 0,
      pageNum: 10,
      pageNo: 0,
      selectRows: [],
      id: null,
      currentNav: null,
    };
  },
  created() {
    this.id = this.$store.state.current_module_id;
    this.currentNav = this.$store.state.currentNav.index;
  },
  methods: {
    onClickHandler(actionItem, scope) {
      // id == null ? actionItem.func : 'editStyle', // editRow/editRowStyle
      // scope.row, //选择哪一行
      if (actionItem.func == "openCalender") {
        this.$refs.calender.openCalender(scope.row.row_id)
      } else {
        this.$emit(
          this.id == null ? actionItem.func : "editStyle",
          scope.row,
          this.getEnterFiled()
        );
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "rowlight";
      } else {
        return "rowdeep";
      }
    },
    handleSelectionChange(rows) {
      this.selectRows = rows;
      console.log(rows); // row = 表格的数据
    },
    getEnterFiled() {
      let Filed = this.tableColumn[0].prop;
      return Filed;
      // console.log(Filed)
    },
    //传来一个布尔条件字符串 执行返回
    istrue(condition, scope) {
      if (condition) {
        return eval(condition);
      }
      return true;
    },
    isShow(value = true) {
      return value;
    },

    //酒店匹配
    matchingHotels() {
      this.tableData.forEach((item1) => {
        this.hotels.forEach((item2) => {
          if (item2.row_id == item1.stay_id) {
            item1.stay_id = item2.name;
          }
        });
      });
    },
    matchingType() {
      this.tableData.forEach((item1) => {
        this.selectType.forEach((item2) => {
          if (item2.row_id == item1.type) {
            item1.type = item2.name;
          }
        });
      });
    },
  },
  beforeUpdate() {
    this.$nextTick(() => {
      //在数据加载完，重新渲染表格咯
      if (this.$refs["table"]) {
        this.$refs["table"].doLayout();
      }
    });
  },
  filters: {
    //根据值返回相应数据
    filterData: function(value, map) {
      return map.get(Number(value)) ? map.get(Number(value)) : "";
    },
  },
};
</script>
<style>
.tablecontaner {
  height: calc(100vh - 160px);
}
.el-link {
  font-size: 12px !important;
}

.el-table .cell.el-tooltip {
  font-size: 12px !important;
}

.footer {
  display: flex;
  margin-top: 12px;
}
.footer > div {
  flex: 1;
}
.footer > div:nth-child(1) {
  text-align: left;
}
.footer > div:nth-child(2) {
  text-align: center;
}
.footer > div:nth-child(3) {
  text-align: right;
}
.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  /* -webkit-box-orient: vertical; */
}
.el-tooltip__popper,
.el-tooltip__popper.is-dark {
  /* background: rgb(48, 65, 86) !important; */
  color: #fff !important;
  /* line-height: 24px; */
}

.el-table__fixed-right-patch {
  width: 0 !important;
}
</style>
