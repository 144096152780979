const isTest = process.env.NODE_ENV === 'test'

let LOGIN_HEADER = ''
let API_HEADER = ''
let API = '/api/gthmmc/index.php?m=amobiletour&'
if (process.env.NODE_ENV === 'production') {
  LOGIN_HEADER = projectConfig.LOGIN_HEADER || 'http://sso.xyabcd.com/ssoauth';
  API_HEADER = projectConfig.API_HEADER || '/gthmmc/index.php?m=amobiletour&'
} else if(isTest){
  LOGIN_HEADER = 'http://test.ssoauth.com/ssoauth';
  API_HEADER = '/gthmmc/index.php?m=amobiletour&'
}else{
  LOGIN_HEADER = 'http://m.cqlpmysblzh.com:8080/ssoauth';
  API_HEADER = '/gthmmc/index.php?m=amobiletour&'
}
export default {
  logIn: LOGIN_HEADER + '/oauth/authorize',
  logOut: LOGIN_HEADER + '/j_spring_security_logout?redirect_uri=',
  oauthGetCurrentUser: API_HEADER + 'c=dataMange&a=oauthGetUser', //获取用户信息
  getResourcefield: API_HEADER + 'c=dataMange&a=getResourcefield', //获取资源字段方法
  queryDataOfResource: API_HEADER + 'c=dataMange&a=queryDataOfResource', //获取资源字段方法(表格数据)
  queryLinkMould: API_HEADER + 'c=dataMange&a=queryLinkMould', //获取链接的功能模块
  addDataOfResource: API_HEADER + 'c=dataMange&a=addDataOfResource', //新增资源字段方法
  modifyDataOfResource: API_HEADER + 'c=dataMange&a=modifyDataOfResource', //修改资源字段方法
  deleteDataOfResource: API_HEADER + 'c=dataMange&a=deleteDataOfResource', //删除资源字段方法
  deleteBatchData: API_HEADER + 'c=dataMange&a=deleteBatchData', //批量删除资源字段方法
  outputplanmodel: API_HEADER + 'c=dataMange&a=outputplanmodel', //导出
  activeStatisticsdata: API_HEADER + 'c=dataMange&a=activeStatisticsdata',
  queryColumnTree: API_HEADER + 'c=dataMange&a=queryColumnTree', //查询树资源
  completeStatisticsdata:API_HEADER+'c=dataMange&a=completeStatisticsdata', //统计分析查询年月，
  modifyexpress:API_HEADER+'c=dataMange&a=modifyexpress', //修改快递
  batchModifyTicketPrice:API_HEADER+'c=dataMange&a=batchModifyTicketPrice'// 批量修改门票价格
}