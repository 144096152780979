<template>
  <div class="kindeditor">
    <textarea
      :id="id"
      name="content"
      v-model="outContent"
      placeholder="请输入"
    ></textarea>
    <input
      @change="selectedFile"
      style="visibility: hidden; height: 0"
      type="file"
      name=""
      id="inputFile"
    />
  </div>
</template>
 
<script>
import "kindeditor/kindeditor-all.js";
import "kindeditor/lang/zh-CN.js";
import "kindeditor/themes/default/default.css";

import { toBlob, minioClient, getUploadUrl, uploadByUrl } from "@minio/minio";
import { getNowTime } from "@common/publicmethods";

export default {
  name: "kindeditor",
  data() {
    return {
      editor: null,
      outContent: this.content,
      fileType: null, //记录点击的文件类型
    };
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    minWidth: {
      type: Number,
      default: 560,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    items: {
      type: Array,
      default: function () {
        return [
          "source",
          "|",
          "undo",
          "redo",
          "|",
          "preview",
          "print",
          "template",
          "code",
          "cut",
          "copy",
          "paste",
          "plainpaste",
          "wordpaste",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyfull",
          "insertorderedlist",
          "insertunorderedlist",
          "indent",
          "outdent",
          "subscript",
          "superscript",
          "clearhtml",
          "quickformat",
          "selectall",
          "|",
          "fullscreen",
          "/",
          "formatblock",
          "fontname",
          "fontsize",
          "|",
          "forecolor",
          "hilitecolor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "lineheight",
          "removeformat",
          "|",
          "image",
          "multiimage",
          "flash",
          "media",
          "insertfile",
          "table",
          "hr",
          "emoticons",
          "baidumap",
          "pagebreak",
          "anchor",
          "link",
          "unlink",
          "|",
          "about",
        ];
      },
    },
    noDisableItems: {
      type: Array,
      default: function () {
        return ["source", "fullscreen"];
      },
    },
    filterMode: {
      type: Boolean,
      default: true,
    },
    htmlTags: {
      type: Object,
      default: function () {
        return {
          font: ["color", "size", "face", ".background-color"],
          span: ["style"],
          div: ["class", "align", "style"],
          table: [
            "class",
            "border",
            "cellspacing",
            "cellpadding",
            "width",
            "height",
            "align",
            "style",
          ],
          "td,th": [
            "class",
            "align",
            "valign",
            "width",
            "height",
            "colspan",
            "rowspan",
            "bgcolor",
            "style",
          ],
          a: ["class", "href", "target", "name", "style"],
          embed: [
            "src",
            "width",
            "height",
            "type",
            "loop",
            "autostart",
            "quality",
            "style",
            "align",
            "allowscriptaccess",
            "/",
          ],
          img: [
            "src",
            "width",
            "height",
            "border",
            "alt",
            "title",
            "align",
            "style",
            "/",
          ],
          hr: ["class", "/"],
          br: ["/"],
          "p,ol,ul,li,blockquote,h1,h2,h3,h4,h5,h6": ["align", "style"],
          "tbody,tr,strong,b,sub,sup,em,i,u,strike": [],
        };
      },
    },
    wellFormatMode: {
      type: Boolean,
      default: true,
    },
    resizeType: {
      type: Number,
      default: 2,
    },
    themeType: {
      type: String,
      default: "default",
    },
    langType: {
      type: String,
      default: "zh-CN",
    },
    designMode: {
      type: Boolean,
      default: true,
    },
    fullscreenMode: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
    },
    themesPath: {
      type: String,
    },
    pluginsPath: {
      type: String,
      default: "",
    },
    langPath: {
      type: String,
    },
    minChangeSize: {
      type: Number,
      default: 5,
    },
    loadStyleMode: {
      type: Boolean,
      default: true,
    },
    urlType: {
      type: String,
      default: "",
    },
    newlineTag: {
      type: String,
      default: "p",
    },
    pasteType: {
      type: Number,
      default: 2,
    },
    dialogAlignType: {
      type: String,
      default: "page",
    },
    shadowMode: {
      type: Boolean,
      default: true,
    },
    zIndex: {
      type: Number,
      default: 811213,
    },
    useContextmenu: {
      type: Boolean,
      default: true,
    },
    syncType: {
      type: String,
      default: "form",
    },
    indentChar: {
      type: String,
      default: "\t",
    },
    cssPath: {
      type: [String, Array],
    },
    cssData: {
      type: String,
    },
    bodyClass: {
      type: String,
      default: "ke-content",
    },
    colorTable: {
      type: Array,
    },
    afterCreate: {
      type: Function,
    },
    afterChange: {
      type: Function,
    },
    afterTab: {
      type: Function,
    },
    afterFocus: {
      type: Function,
    },
    afterBlur: {
      type: Function,
    },
    afterUpload: {
      type: Function,
    },
    uploadJson: {
      type: String,
    },
    fileManagerJson: {
      type: Function,
    },
    allowPreviewEmoticons: {
      type: Boolean,
      default: true,
    },
    // 本地上传
    allowImageUpload: {
      type: Boolean,
      default: false,
    },
    allowFlashUpload: {
      type: Boolean,
      default: false,
    },
    allowMediaUpload: {
      type: Boolean,
      default: false,
    },
    allowFileUpload: {
      type: Boolean,
      default: true,
    },
    allowFileManager: {
      type: Boolean,
      default: false,
    },
    fontSizeTable: {
      type: Array,
      default: function () {
        return ["9px", "10px", "12px", "14px", "16px", "18px", "24px", "32px"];
      },
    },
    // 0 网络图片 1本地图片
    imageTabIndex: {
      type: Number,
      default: 0,
    },
    formatUploadUrl: {
      type: Boolean,
      default: true,
    },
    fullscreenShortcut: {
      type: Boolean,
      default: false,
    },
    extraFileUploadParams: {
      type: Array,
      default: function () {
        return [];
      },
    },
    filePostName: {
      type: String,
      default: "imgFile",
    },
    fillDescAfterUploadImage: {
      type: Boolean,
      default: false,
    },
    afterSelectFile: {
      type: Function,
    },
    pagebreakHtml: {
      type: String,
      default: "<hr style=”page-break-after: always;” class=”ke-pagebreak” />",
    },
    // 在线上传
    allowImageRemote: {
      type: Boolean,
      default: false,
    },
    autoHeightMode: {
      type: Boolean,
      default: false,
    },
    fixToolBar: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
    },
    fieldName: {
      type: String,
      default: "",
    },
  },
  watch: {
    content(val) {
      this.editor && val !== this.outContent && this.editor.html(val);
    },
    outContent(val) {
      // console.log(this.editor.html());
      this.editorText = val;
      this.$emit("kindeditorChange", this.editorText, this.fieldName);
      //this.$emit("on-content-change", val);
      // console.log(this.fieldName)
    },
  },
  mounted() {
    var _this = this;
    _this.editor = window.KindEditor.create("#" + this.id, {
      width: _this.width,
      height: _this.height,
      minWidth: _this.minWidth,
      minHeight: _this.minHeight,
      items: _this.items,
      noDisableItems: _this.noDisableItems,
      filterMode: _this.filterMode,
      htmlTags: _this.htmlTags,
      wellFormatMode: _this.wellFormatMode,
      resizeType: _this.resizeType,
      themeType: _this.themeType,
      langType: _this.langType,
      designMode: _this.designMode,
      fullscreenMode: _this.fullscreenMode,
      basePath: _this.basePath,
      themesPath: _this.cssPath,
      pluginsPath: _this.pluginsPath,
      langPath: _this.langPath,
      minChangeSize: _this.minChangeSize,
      loadStyleMode: _this.loadStyleMode,
      urlType: _this.urlType,
      newlineTag: _this.newlineTag,
      pasteType: _this.pasteType,
      dialogAlignType: _this.dialogAlignType,
      shadowMode: _this.shadowMode,
      zIndex: _this.zIndex,
      useContextmenu: _this.useContextmenu,
      syncType: _this.syncType,
      indentChar: _this.indentChar,
      cssPath: _this.cssPath,
      cssData: _this.cssData,
      bodyClass: _this.bodyClass,
      colorTable: _this.colorTable,
      afterCreate: _this.afterCreate,
      afterChange: function () {
        _this.afterChange;
        _this.outContent = this.html();
      },
      afterTab: _this.afterTab,
      afterFocus: _this.afterFocus,
      afterBlur: _this.afterBlur,
      afterUpload: _this.afterUpload,
      uploadJson: _this.uploadJson,
      fileManagerJson: _this.fileManagerJson,
      allowPreviewEmoticons: _this.allowPreviewEmoticons,
      allowImageUpload: _this.allowImageUpload,
      allowFlashUpload: _this.allowFlashUpload,
      allowMediaUpload: _this.allowMediaUpload,
      allowFileUpload: _this.allowFileUpload,
      allowFileManager: _this.allowFileManager,
      fontSizeTable: _this.fontSizeTable,
      imageTabIndex: _this.imageTabIndex,
      formatUploadUrl: _this.formatUploadUrl,
      fullscreenShortcut: _this.fullscreenShortcut,
      extraFileUploadParams: _this.extraFileUploadParams,
      filePostName: _this.filePostName,
      fillDescAfterUploadImage: _this.fillDescAfterUploadImage,
      afterSelectFile: _this.afterSelectFile,
      pagebreakHtml: _this.pagebreakHtml,
      allowImageRemote: _this.allowImageRemote,
      autoHeightMode: _this.autoHeightMode,
      fixToolBar: _this.fixToolBar,
      tabIndex: _this.tabIndex,
      // 图片上传
      imageUpload: function (url, data, name) {
        //上传文件后执行的回调函数，必须为3个参数
        // console.log("------", url);
        if (name == "image" || name == "multiimage") {
          //单个和批量上传图片时
          const iframe = document.getElementsByTagName("iframe")[0];
          const body =
            iframe.contentDocument.getElementsByClassName("ke-content")[0];
          //console.log(body.innerHTML);
          body.innerHTML += `<div ><img src=${url} class="u-image"/></div>`;
        }
      },
      // 音视频上传
      video_audioUpload: function (url, type) {
        // console.log("uuuuu", url);
        const iframe = document.getElementsByTagName("iframe")[0];
        const body =
          iframe.contentDocument.getElementsByClassName("ke-content")[0];
        //console.log(body.innerHTML);
        if (type == "video") {
          body.innerHTML += `<div ><video src=${url} width="400px" height="250px" controls loop /></div>`;
        } else {
          body.innerHTML += `<div><audio src=${url} controls /></div>`;
        }
      },
      // 文件上传
      fileUpload: function (url, name) {
        // console.log("uuuuu", url);
        const iframe = document.getElementsByTagName("iframe")[0];
        const body =
          iframe.contentDocument.getElementsByClassName("ke-content")[0];
        //console.log(body.innerHTML);

        body.innerHTML += `<div><a download=${url} href=${url} >${name}</a></div>`;
      },
    });
    // 添加点击图片回调函数
    _this.editor.clickToolbar("image", () => {
      // 禁用自带的图片弹窗
      this.editor.hideDialog();
      // 打开文件
      this.handleOpenFile();
      // 设置文件类型
      this.fileType = "image";
    });
    _this.editor.clickToolbar("media", () => {
      // 禁用自带的弹窗
      this.editor.hideDialog();
      // 打开文件
      this.handleOpenFile();

      // 设置文件类型
      this.fileType = "media";
      // console.log(this.fileType);
    });

    _this.editor.clickToolbar("insertfile", () => {
      // 禁用自带的弹窗
      this.editor.hideDialog();
      // 打开文件
      this.handleOpenFile();

      // 设置文件类型
      this.fileType = "file";
      // console.log(this.fileType);
    });
  },
  methods: {
    // 打开文件
    handleOpenFile() {
      let input = window.document.getElementById("inputFile");
      // 解决同一个文件不能监听的问题
      input.addEventListener(
        "click",
        function () {
          this.value = "";
        },
        false
      );
      // 点击input
      input.click();
    },
    // 选择文件
    async selectedFile($event) {
      const file = $event.target.files[0];
      // 1.判断文件并上传
      this.judgeFileTypeAndUpload(file);
      // console.log(file);

      // 上传图片

      //console.log($event,file,this.file2Base64(file,res=>{console.log(res)}))
      // this.file2Base64(file,res=>{
      //   this.$emit('base64Flie',res)
      // })
    },
    // file2Base64(file, cb) {
    //   let url = URL.createObjectURL(file);
    //   let reader = new FileReader();
    //   reader.onload = function (evt) {
    //     let base64 = evt.target.result;
    //     cb(base64);
    //   };
    //   reader.readAsDataURL(file);
    // },

    // 显示图片
    showImg(url) {
      //this.editor.imageUpload(url, null, "image");
      // document.getElementById("piclist").selectedIndex += 1; //选定刚新增的这一条
      // window
      //   .KindEditor("#indexpicimg")
      //   .html("<img src='" + url + "' width='150' height='95' />"); //重置图片展示区DIV的HTML内容
      // window.KindEditor("#pic").val(url); //重置文本框值
      //console.log("url", url);
      // document.getElementById("piclist").options[
      //   document.getElementById("piclist").length
      // ] = new Option(url, url); //下拉列表框增加一条
    },
    // 处理返回的url
    filepathfilter(value) {
      if (value) {
        let index2 = value.lastIndexOf("?");
        console.log("index2", index2);
        value = value.substring(0, index2);
        return value;
      }
    },
    // 判断文件类型并上传
    judgeFileTypeAndUpload(file) {
      let fileType = file.type;
      const regImage = /^image.*/;
      const regVideo = /^video.*/;
      const regAudio = /^audio.*/;
      const regFile = /^application.*/;
      // console.log(fileType, regImage.test(fileType));
      if (this.fileType == "image") {
        if (regImage.test(fileType)) {
          this.imageUpload_(file);
        } else {
          this.$message({
            message: "不是图片！",
            type: "error",
          });
        }
      }
      if (this.fileType == "media") {
        if (regVideo.test(fileType)) {
          // console.log("video");
          this.videoUpload_(file);
        } else if (regAudio.test(fileType)) {
          // console.log("audio");
          this.audioUpload_(file);
        } else {
          this.$message({
            message: "不是媒体资源！",
            type: "error",
          });
        }
      }

      if (this.fileType == "file") {
        if (regFile.test(fileType)) {
          this.fileUpload_(file);
        }else{
          this.$message({
            message: "不是文件资源！",
            type: "error",
          });
        }
      }
    },

    // 图片上传
    imageUpload_(file) {
      let fname = getNowTime("time") + file.name;
      getUploadUrl(`images`,fname)
        .then((res) => {
          // console.log("res", res);
          uploadByUrl(res, file)
            .then((res) => {
              // console.log("ress", res);

              //this.showImg(this.filepathfilter(res.url));
              this.editor.imageUpload(
                this.filepathfilter(res.url),
                null,
                "image"
              );
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // 音频上传
    audioUpload_(file) {
      let fname = getNowTime("time") + file.name;
      getUploadUrl(`files`,fname)
        .then((res) => {
          // console.log("res", res);
          uploadByUrl(res, file)
            .then((res) => {
              // console.log("ress", res);

              //this.showImg(this.filepathfilter(res.url));
              this.editor.video_audioUpload(
                this.filepathfilter(res.url),
                "audio"
              );
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // 视频上传
    videoUpload_(file) {
      let fname = getNowTime("time") + file.name;
      getUploadUrl(`videos`,fname)
        .then((res) => {
          // console.log("res", res);
          uploadByUrl(res, file)
            .then((res) => {
              // console.log("ress", res);

              //this.showImg(this.filepathfilter(res.url));
              this.editor.video_audioUpload(
                this.filepathfilter(res.url),
                "video"
              );
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // 文件上传
    fileUpload_(file) {
      let fname = getNowTime("time") + file.name;
      getUploadUrl(`files`,fname)
        .then((res) => {
          // console.log("res", res);
          uploadByUrl(res, file)
            .then((res) => {
              // console.log("ress", res);

              //this.showImg(this.filepathfilter(res.url));
              this.editor.fileUpload(this.filepathfilter(res.url), file.name);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    del() {
      // console.log(this.$refs.v);
    },
  },
};
</script>
<style lang="scss" scoped>
.u-image {
  width: 100px;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
}
// .kindeditor{
//   width: 560px !important;
// }
</style>