<template>
  <div style="position: relative">
    <!-- 文件上传 -->
    <!-- 该处的elementUI只是为了显示原来的样式，不起任何上传的作用 -->
    <el-upload action="" list-type="text">
      <el-button size="mini" type="primary">点击上传</el-button>
      <div v-show="!filesrc" slot="tip" class="el-upload__tip">
        <!-- 暂无文档 仅允许上传文档类型 -->
      </div>
    </el-upload>
    <!-- 此处input才是上传的部分 -->
    <input
      accept=".doc,.docx,.mp3,.mp4,.avi,.mov,.rmvb,.flv,.3gp"
      type="file"
      name=""
      class="file"
      @change="change"
      ref="fileUpload"
      v-show="true"
    />
    <a v-show="filesrc !== ''" :download="filesrc" :href="filesrc">{{
      filesrc | filepathfilter
    }}</a>
    <!-- <a
      v-show="filesrc !== ''"
      :download="(filesrc ? '/back/gthmmc' : '') + filesrc"
      :href="(filesrc ? '/back/gthmmc' : '') + filesrc"
      >{{ filesrc | filepathfilter }}</a
    > -->
    <el-tooltip
      v-show="filesrc"
      style="margin-left: 20px"
      content="删除"
      :enterable="false"
      effect="dark"
      placement="top"
    >
      <el-link
        icon="el-icon-delete"
        :underline="false"
        type="primary"
        @click="handleRemove(filedname)"
      ></el-link>
    </el-tooltip>
  </div>
</template>

<script>
const stream = require("stream");
import { getUploadUrl, uploadByUrl, removeObject } from "@minio/minio";
import { getNowTime, getImgName } from "@common/publicmethods";
export default {
  props: ["filesrc", "filedname"],
  data() {
    return {
      dialogVisible: {},
      fileList: [],
      upload: false,
      fileName: "",
    };
  },

  methods: {
    change(files) {
      console.log("fffff", files);
      let file = this.$refs.fileUpload.files[0];
      console.log("---", file);
      let folderName = '';
      if(file.type.indexOf('video') != -1){
        folderName = 'videos'
      }else{
        folderName = 'files'
      }
      let fileName = getNowTime("time") + file.name; // 获取带时间的文件名
      let fileDate = getNowTime("fileDate") // 获取当天文件夹名
      // 1. 通过stream上传
      //uploadMinIo("medialibrary",file,"test",this);

      // 2. 通过预签名的url上传
      getUploadUrl(folderName, fileName)
        .then((res) => {
          //console.log("res", res.replace(/http:\/\/test.minio.com/,"/minio"));
          uploadByUrl(res, file)
            .then((res) => {
              console.log("ress", res);
              // 此处的响应有两种写法
              // 1. 可以直接手写地址，因为已经设置了链接永久访问
              // 2. 可以拿到res.url ，进行地址分割一下，只要问号前面的字符串就行，图片上传使用了这种方法
              // 此处直接手写地址，建议使用第二种写法
              let response = {
                path: this.getImgName1(res.url),
                result: 1,
              };
              // 成功生成url后调用
              this.handleAvatarSuccess(response, this.filedname);
              this.fileName = fileName;
              this.$message({
                message: "上传成功！",
                type: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {});
    },
    handleAvatarSuccess(res, name) {
      if (res.result == "1") {
        this.$bus.$emit("imgUploaded", res, name);
        // this.$bus.$emit("fileUploaded", res, name);
        console.log('handleAvatarSuccess',name)
      } else {
        this.fileList = [];
        this.$message({
          message: res.message,
          type: "info",
        });
      }
    },

    // 删除文件
    handleRemove(itemname) {
      //this.$bus.$emit("imgRemoved", itemname);
      this.$confirm("此操作将永久删除该服务器的文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(getImgName(this.filesrc));
          removeObject(getImgName(this.filesrc))
            .then((res) => {
              console.log(getImgName(this.filesrc));
              this.$bus.$emit("imgRemoved", itemname);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    previewImg(itemname) {
      this.$set(this.dialogVisible, itemname, true);
    },
    fileProgress(e) {
      console.log(e);
    },
    // url解析
    getImgName1(url) {
      if (url) {
        let index = url.lastIndexOf("?");
        url = index == -1 ? url : url.substring(0, index);
        return url;
      }
    },
    // 上传
  },
  filters: {
    filepathfilter: function (value) {
      if (value) {
        let index1 = value.lastIndexOf("/");
        let index2 = value.lastIndexOf("?");
        console.log("index2", index2);
        value =
          index2 != -1
            ? value.substring(index1 + 1, index2)
            : value.substring(index1 + 1, value.length);
        return value;
      }
    },
  },
};
</script>

<style scoped>
.file {
  width: 100px;
  height: 36px;
  opacity: 0;
  border-radius: 6px;
  position: absolute;
  top: 0px;
  left: 0px;
  outline: none;
  z-index: 99;
}
.file:hover {
  cursor: pointer;
}
</style>