import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
      token:'',
      userInfo:{},
      login_status:false,
      dataPrower:[],
      currentNav:{},
      is_resource: false,
      is_identify_only: false,
      current_module_id: null, //当前选中的模板
      current_year: null, // 记录统计中的年份
      current_month: null, // 记录统计中的月份
      minio: null,
      viewWin:{},
      windowWidth:null,
      currentParnetIndex:"",
      currentPosition:0,
      moduleState:""
    },
    mutations: {
      setToken(state,payload){
        state.token = payload
      },
      setUserInfo(state,payload){
        state.userInfo = payload
      },
      setLoginStatus(state,payload){
        state.login_status = payload
      },
      setDataPrower(state,payload){
        state.dataPrower = payload
      },
      setcurrentNav(state,payload){
        state.currentNav = payload
      },
      setIsResource(state,payload){
        state.is_resource = payload
      },
      setIsIdentifyOnly(state,payload){
        state.is_identify_only = payload
      },
      setCurrentModuleId(state,payload){
        state.current_module_id = payload
      },
      setCurrentYear(state,payload){
        state.current_year = payload
      },
      setCurrentMonth(state,payload){
        state.current_month = payload
      },
      setMinio(state,payload){
        state.minio = payload
      },
      setWH(state,payload){
        state.viewWin = payload
      },
      setWindowWidth(state,payload){
        state.windowWidth = payload
      },
      setCurrentParnetIndex(state,payload){
        state.currentParnetIndex = payload
      },
      setCurrentPosition(state,payload){
        state.currentPosition = payload
      },
      setModuleState(state,payload){
        state.moduleState = payload
      }
    },
    actions: {
      
    },
    getters: {
      
    }
})

export default store