<template>
  <div>
    <el-tabs
      type="border-card"
      v-loading="loading"
      element-loading-text="拼命加载中"
      v-model="activeName"
      @tab-remove="removeTab"
      @tab-click="handleClick"
    >
      <!-- activeName的默认值为 sjy -->
      <el-tab-pane
        :closable="item.closable"
        v-for="(item, index) in tabitem"
        :key="index"
        :name="item.name"
        :label="item.label"
      >
        <slot :name="item.label"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: ["tabitem", "loading","isCustomCloseTab"],
  data() {
    return {
      activeName: this.tabitem[0].name, // sjy
    };
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
      this.$emit("handleTabClick", tab);
    },
    removeTab(name, isReset) {
      if (name == "stylePage") {
        this.$store.commit("setCurrentModuleId", null);
      }
      this.$emit("closeTab", name); // 向父组件中传递closeTab事件
      if(this.isCustomCloseTab) return; // 自定义返回
      // console.log(this.tabitem)
      // 如果isReset有值切为resetTabitem说明是点击切换栏目触发的，按理说应该把用户打开的所有的选项卡关闭
      console.table(name, isReset, this.tabitem);
      if (isReset === "resetTabitem") {
        let index = this.tabitem.findIndex((item) => {
          return item.name == "sjy";
        });
        this.tabitem.splice(1);
      } else {
        this.tabitem.forEach((element, index) => {
          if (element.name == name) {
            this.tabitem.splice(index, 1);
          }
        });
      }
      if (isReset === "resetTabitem") {
        this.activeName = this.tabitem[0].name;
      } else {
        if (this.$store.state.current_module_id != null) {
          this.activeName = this.tabitem[1].name;
        } else {
          this.activeName = this.tabitem[0].name;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.el-tabs__content {
  //height: 85%;
  //overflow-y: scroll!important;
  //justify-content: space-around;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding-top: 0 !important;
}
</style>