<template>
    <div class="maintabnav">
    <div class="logo">
      <!-- <img src="~@assets/images/title_logo.png" alt="" srcset="" /> -->
      <!-- <img :src="userInfo.image_sys || '' " alt=""> -->
      <span style="margin-left:60px">{{userInfo.newName? userInfo.newName:userInfo.sys}}</span>
    </div>
    <div class="infobox">
      <!-- <img class="systemIcon" :style="userInfo.image ? 'margin-left: 20px;' : 'margin-left: 0px;'  " :src="userInfo.image || '' " alt=""></img> -->
      <img class="systemIcon" :src="userInfo.image || '' " :style="userInfo.image ? 'margin-right: 20px;' : 'margin-right: 0px;'  " alt=""  >
      <span> {{`你好，${$store.state.userInfo.name}`  }}</span>
      <span>{{ date }}</span>
      <span>{{ time }}</span>
      
      <!-- <span
        @mouseenter="isShowLogout = true"
        @mouseleave="isShowLogout = false"
      >
        <span class="showout" @click="isShowLogout = !isShowLogout">
          <span> {{$store.state.userInfo.name}}</span>
          <span>
            <i v-if="!isShowLogout" class="el-icon-arrow-down"></i>
            <i v-else class="el-icon-arrow-up"></i>
          </span>
        </span>
        <transition name="slide-fade">
          <div v-if="isShowLogout" class="logout" @click="logout">退出登录</div>
        </transition>
      </span> -->
      <!-- 退出登录 -->
      <div class="box_r">
        <span style="color: rgb(214, 213, 213);position: relative;top: -2px;">|</span>
      <el-tooltip
          class="item"
          effect="dark"
          :enterable="false"
          content="退出登录"
          placement="top"
        >
          <i
            class="el-icon-switch-button"
            style="
            font-size: 20px;
            font-weight: 100;
            line-height: 60px;
            margin-left: 5px;
            cursor: pointer;
            color: #1871fc;"
            @click="logout"
          ></i>
      </el-tooltip>
      </div>  
     
     
    </div>

    <!-- 顶部主导航 -->
  </div>
 
</template>

<script>
import { logout } from "@/login/conf/commonFunc.js";
import { formatDate, getWeek } from "@/common/publicmethods";
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      time: formatDate(new Date(), "hh:mm:ss"),
      date: formatDate(new Date(), "yyyy/MM/dd"),
      week: getWeek(),
      isShowLogout: false,
      userInfo:{},
      isShow:true
    }
  },

  methods: {
    logout() {
      logout();
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    //获取用户信息
    getUserInfo(){
      this.userInfo = this.$store.state.userInfo;
      console.log(this.userInfo)
    }
  },
  updated(){
    if(this.isShow){
      // console.log('我执行了吗')
      this.getUserInfo()
      this.isShow = false
    }
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.time = formatDate(new Date(), "hh:mm:ss"); // 修改数据date
    }, 1000)

    
    // //获取用户数据
    // this.userInfo = this.$store.state.userInfo;
    // console.log(this.userInfo)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
};
</script>

<style lang="scss"  scoped >
.maintabnav {
  box-shadow: 2px 0 2px 2px #bebebe;
  justify-content: space-between;
}

.logo > img {
  vertical-align: middle;
  margin-left: 24px;
  margin-right: 10px;
  margin-top: -5px;
  width: 30px;
  border-radius: 50%;
}
.logo > span {
  // margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #555;
  position: relative;
  top: -2px;
}

.infobox {
  // margin-left: auto;
  padding-right: 30px;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000000;
  .systemIcon{
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: -4px;
  }
  span {
    margin-left: 8px;
    img {
      margin-left: 3px;
    }
  }
}
.logout {
  position: absolute;
  right: 40px;
  top: 60px;
  z-index: 99;
  width: 150px;
  height: 55px;
  text-align: center;
  background-color: #0369e6;
  font-size: 14px;
  cursor: pointer;
}
.showout {
  cursor: pointer;
  display: inline-block;
  height: 60px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
</style>