import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
//数据资源
import Home from '@views/home/Home.vue'
import ModelManage from '@views/modelmanage/ModelManage.vue'
import ScenicManage from '@views/modelmanage/ScenicManage.vue'
import ShopManage from '@views/modelmanage/ShopManage.vue'
import Login from '@/login/login.vue'
const SystemConfig = ()=>import("@views/systemconfig/SystemConfig.vue")
const ColumnConfig = ()=>import('@views/columnconfig/ColumnConfig.vue')
const StatisticAlanalysis  =()=> import("@/views/statistic-alanalysis/StatisticAlanalysis.vue")
const ProductOrder = ()=> import("@/views/productOrder/productOrder.vue")
const TicketConfig = () => import("@/views/systemconfig/TicketConfig.vue")
const Strategy = () => import("@/views/strategy/index.vue")
// const Demo = ()=> import('@views/demo/Demo')
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'home' },
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/modelmanage',
        name: 'modelmanage',
        component: ModelManage,
    },
    {
        path: '/loginpage',
        name: 'loginpage',
        // redirect: { name: 'basedata' },
        component: Login,
    },
    {
        path: '/amt_basicconfig',
        name: 'amt_basicconfig',
        component: SystemConfig,
    },
    {
        path: '/amt_columnconfig',
        name: 'amt_columnconfig',
        component: ColumnConfig,
    },
    {
        path: '/foo',
        name: 'foo',
        component: StatisticAlanalysis,
    },
    {
        path: '/order',
        name: 'order',
        component: ProductOrder,
    },
    {
        path: '/ticket_config',
        name: "ticket_config",
        component: TicketConfig,
    },
    {
        path: '/scenic_manage',
        name: "play",
        component: ScenicManage,
    },
    {
        path: '/shop_manage',
        name: "commodity",
        component: ShopManage,
    },
    {
        path: '/strategy',
        name: "amt_strategy",
        component: Strategy,
    }
]
//解决vuecli路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})



router.beforeEach((to, from, next) => {
    if (to.name !== 'loginpage' && !store.state.login_status) next({ name: 'loginpage' })
    else next()



})

export default router